// This converts raw user search text <input> into a string we can safely put in the URL slug
const userTextInputToUrlSlug = (textInput) => {
  if (!textInput) return ''
  textInput = textInput.toLowerCase()
  textInput = textInput.replace(/[^a-z0-9À-ÿ\s-]/g, '') // remove any non alpha characters
  textInput = textInput.replace(/\s+/g, '-') // replace 1 or more SPACE in a row with a single -
  textInput = textInput.replace(/-+/g, '-') // replace 1 or more HYPHEN in a row with a single -
  textInput = textInput.replace(/^-/, '') // remove any + from start of string
  textInput = textInput.replace(/-$/g, '') // removing any + from end of string
  return textInput
}

// This returns the root item type path for the supplied item type
const getItemTypePath = ({ itemType }) => {
  if (['video', 'videos'].includes(itemType)) {
    return '/free-stock-video/'
  }
  if (itemType === 'art') {
    return '/free-stock-art/'
  }
  if (itemType === 'video_templates') {
    return '/free-video-templates/'
  }
  if (itemType === 'video_templates_after_effects') {
    return '/free-after-effects-templates/'
  }
  if (itemType === 'video_templates_davinci_resolve') {
    return '/free-davinci-resolve-templates/'
  }
  if (itemType === 'video_templates_final_cut_pro') {
    return '/free-final-cut-pro-templates/'
  }
  if (itemType === 'video_templates_premiere_pro') {
    return '/free-premiere-pro-templates/'
  }
  if (itemType === 'music') {
    return '/free-stock-music/'
  }
  if (itemType === 'sfx') {
    return '/free-sound-effects/'
  }
  if (itemType === 'icons') {
    return 'https://www.reshot.com/free-svg-icons/'
  }
  return '/'
}

const getItemSearchPath = ({ itemType, searchTermUrlSlug } = {}) => {
  const searchSlug = itemType === 'icons' ? '' : 'discover/'
  let searchUrl = `${getItemTypePath({ itemType })}${searchSlug}`
  if (searchTermUrlSlug) {
    const analyticsParams = itemType === 'icons' ? '?utm_source=mixkit&utm_campaign=mixkit-header-search' : ''
    searchUrl = `${searchUrl}${encodeURIComponent(searchTermUrlSlug)}/${analyticsParams}`
  }
  return searchUrl
}

export { userTextInputToUrlSlug, getItemSearchPath }
